* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman'; }

body {
  background-color: #d6d6d6; }
  body.overflow-hidden {
    overflow: hidden; }

button {
  outline: none;
  border: none; }

.container {
  max-width: 1320px;
  margin: 0 auto; }

@media (max-width: 1368px) {
  .container {
    max-width: 1140px; } }

@media (max-width: 1199px) {
  .container {
    max-width: 960px; } }

@media (max-width: 991px) {
  .container {
    max-width: 720px; } }

@media (max-width: 767px) {
  .container {
    max-width: 540px; }
  .container.break-at-md {
    max-width: 100%; } }

@media (max-width: 575px) {
  .container {
    max-width: 100%;
    margin: 0;
    padding: 0 15px; }
  .container.break-at-md {
    padding: 0; } }

.header {
  background-color: #08053b;
  color: #dadada;
  position: relative;
  z-index: 100; }
  .header__items {
    padding: 20px 0;
    text-align: justify;
    text-align-last: justify; }
  .header__block {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    text-align-last: right; }
  .header__title {
    font-size: 30px; }
  .header__subtitle {
    font-size: 22px;
    text-align: right; }
  .header__language {
    display: inline-block;
    vertical-align: middle; }
  .header__flag-icon {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    border-radius: 50%; }
    .header__flag-icon img {
      border-radius: 50%;
      width: 100%;
      height: 100%; }
    .header__flag-icon.active {
      position: relative;
      border: 3px solid #00ccff; }
    .header__flag-icon:hover {
      cursor: pointer; }

.header__language-md-devices {
  top: 0;
  left: 0;
  z-index: 200;
  position: absolute;
  background-color: #35af46;
  display: none;
  border-radius: 20px; }

.header__flag-icon-md-devices {
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  .header__flag-icon-md-devices img {
    border-radius: 50%;
    width: 100%;
    height: 100%; }

@media (max-width: 767px) {
  .header {
    position: fixed;
    width: 100%; }
    .header__items {
      padding: 5px 0;
      direction: rtl; }
    .header__block {
      text-align: center;
      text-align-last: center; }
    .header__title {
      margin-bottom: 5px;
      font-size: 20px; }
    .header__subtitle {
      font-size: 16px; }
    .header__language {
      margin: 0; }
    .header__flag-icon.non-active {
      display: none; }
    .header__flag-icon.active:hover {
      border: none; }
    .header__flag-icon.active:hover .header__language-md-devices {
      display: block; }
    .header__flag-icon.active:hover .header__language-md-devices .header__flag-icon-md-devices.active {
      border: 3px solid #00ccff;
      margin-bottom: 5px; }
    .header__menu-btn {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      width: 30px;
      height: 3px;
      background-color: transparent; }
      .header__menu-btn::before, .header__menu-btn::after {
        content: '';
        position: absolute;
        left: 0;
        display: inline-block;
        width: 30px;
        height: 3px;
        background-color: #fff; }
      .header__menu-btn::before {
        top: 0;
        transform: rotate(45deg); }
      .header__menu-btn::after {
        bottom: 0;
        transform: rotate(-45deg); }
      .header__menu-btn.closed {
        background-color: #fff; }
        .header__menu-btn.closed::before {
          top: -10px;
          transform: rotate(0); }
        .header__menu-btn.closed::after {
          bottom: -10px;
          transform: rotate(0); } }

@media (max-width: 575px) {
  .header__title {
    font-size: 18px; }
  .header__subtitle {
    font-size: 16px; } }

@media (max-width: 520px) {
  .header__title {
    font-size: 16px; }
  .header__subtitle {
    font-size: 14px; } }

@media (max-width: 400px) {
  .header__title {
    width: 200px;
    margin-bottom: 0; }
  .header__subtitle {
    display: none; } }

.navigation {
  position: relative;
  z-index: 99; }
  .navigation__items {
    position: relative;
    border-left: none;
    background-color: #4b4885;
    display: inline-block;
    width: 550px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    text-align: center; }
  .navigation__button {
    text-decoration: none;
    display: inline-block;
    font-family: 'Arial';
    background-color: #4b4885;
    color: #dadada;
    padding: 10px;
    font-size: 14px; }
    .navigation__button:hover {
      background-color: #423f74;
      cursor: pointer; }
    .navigation__button_active {
      background-color: #222055; }
      .navigation__button_active:hover {
        background-color: #222055;
        cursor: default; }

@media (max-width: 767px) {
  .navigation {
    position: fixed;
    transition: 1s;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #4b4885;
    padding-top: 56px; }
    .navigation_closed {
      transform: translateY(-100%);
      transition: 1s; }
    .navigation__items {
      width: 100%; }
    .navigation__button {
      width: 100%;
      display: block;
      border-bottom: 1px solid #08053b; } }

@media (max-width: 575px) {
  .navigation {
    padding-top: 54px; } }

@media (max-width: 520px) {
  .navigation {
    padding-top: 50px; } }

.goods {
  padding: 50px 0 50px 0; }
  .goods__items {
    margin-bottom: -50px; }
  .goods__col {
    display: inline-block;
    width: 25%;
    margin-right: -4px;
    vertical-align: top; }
  .goods__item {
    display: block;
    color: #000000;
    text-decoration: none;
    margin: 0 auto 50px;
    text-align: center;
    position: relative;
    background-color: #fff;
    width: 250px;
    min-height: 340px;
    padding: 30px;
    border-radius: 20px;
    font-size: 18px; }
    .goods__item:hover {
      cursor: pointer; }
      .goods__item:hover::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0;
        left: 0;
        opacity: 0.3;
        border-radius: 20px; }
  .goods__name {
    font-family: 'Arial';
    font-size: 100%;
    min-height: 85px;
    margin-bottom: 20px; }
  .goods__img {
    margin: 0 auto;
    width: 80%; }
    .goods__img img {
      width: 100%; }

@media (max-width: 1199px) {
  .goods__items {
    margin-bottom: -30px; }
  .goods__item {
    width: 200px;
    min-height: 295px;
    padding: 24px;
    margin-bottom: 30px; } }

@media (max-width: 991px) {
  .goods__col {
    width: 33.33333333%; } }

@media (max-width: 767px) {
  .goods {
    padding: 86px 0 30px 0; }
    .goods__content {
      font-size: 16px; }
    .goods__items {
      margin-bottom: -20px; }
    .goods__item {
      width: 150px;
      min-height: 216px;
      padding: 15px;
      font-size: 14px;
      margin: 0 auto 20px; }
    .goods__name {
      min-height: 65px;
      margin-bottom: 10px; }
    .goods__img {
      margin: 0 auto; } }

@media (max-width: 575px) {
  .goods {
    padding: 74px 0 20px 0; } }

@media (max-width: 520px) {
  .goods__col {
    width: 50%; } }

@media (max-width: 355px) {
  .goods__item {
    width: 130px;
    min-height: 213px; }
  .goods__name {
    min-height: 80px; } }

body {
  min-height: 100vh;
  padding-bottom: 41px;
  position: relative; }

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #08053b;
  color: #dadada;
  padding: 10px 0;
  font-size: 18px; }

@media (max-width: 767px) {
  body {
    padding-bottom: 36px; }
  .footer {
    font-size: 14px; } }

@media (max-width: 458px) {
  body {
    padding-bottom: 52px; } }
